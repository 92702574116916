import styled from 'styled-components'

export const SectionHeader = styled.h1`
  color: #fff;
  margin: 0 auto 30px auto;
  font-size: 1.8rem;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 1.4em;
  }
  @media screen and (max-width: 480px) {
    font-size: 1.2em;
  }
`