import React, { useState } from "react";
import HeroSection from "../components/HeroSection";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import TeamSection from "../components/Team";
import ProjectsSection from "../components/Projects";
import ContactSection from "../components/Contact";
import background from "../assets/background.jpg";
import styled from "styled-components";
import CookieConsent from "react-cookie-consent";

const Home = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const SiteContainer = styled.div`
    background: #0c0c0c url("${background}") no-repeat top center;
    background-attachment: fixed;
    background-size: cover;
  `;

  return (
    <SiteContainer>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <ProjectsSection />
      <TeamSection />
      <ContactSection />
      <CookieConsent
        location="none"
        buttonText="AKCEPTUJĘ"
        cookieName="tcreateCookie"
        style={{
          width: "80%",
          background: "#e7e6e7",
          color: "#333",
          position: "fixed",
          maxWidth: "400px",
          bottom: "20px",
          left: "20px",
          right: "20px",
          fontSize: "13px",
          lineHeight: "20px",
        }}
        buttonStyle={{
          background: "#ffc20e",
          color: "#333",
          fontSize: "13px",
          fontWeight: "bold",
        }}
        contentStyle={{ flex: "auto", marginBottom: "0px" }}
        expires={150}
      >
        Strona wykorzystuje pliki cookies. Korzystanie z witryny bez zmiany
        ustawień dotyczących cookies oznacza, że będą one zamieszczone.
      </CookieConsent>
    </SiteContainer>
  );
};

export default Home;
