import React, { useState } from "react";
import {
  ProjectsContainer,
  ProjectContent,
  ProjectImgContainer,
  ProjectP,
  ProjectSlide,
  AnimatedContainer,
} from "./ProjectsElements";
import ProjectsImg from "../../assets/sections-images/projects-img.png";
import ProjectsImg2x from "../../assets/sections-images/projects-img@2x.png";
import Projects2Img from "../../assets/sections-images/projects2-img.png";
import Projects2Img2x from "../../assets/sections-images/projects2-img@2x.png";
import Projects3Img from "../../assets/sections-images/projects3-img.png";
import Projects3Img2x from "../../assets/sections-images/projects3-img@2x.png";
import { animated, useTransition } from "react-spring";
import { useSwipeable } from "react-swipeable";
import LogoSliderNav from "../LogoSliderNav";
import { SectionHeader } from "../SectionHeader";

import MastermediaLogo from '../../assets/logo/mastermedia.png'
import MptLogo from '../../assets/logo/mpt.png'
import PodravkaLogo from '../../assets/logo/podravka.png'

const AnimatedDisplay = ({
  item,
  activeSlide,
  slides,
  setActiveSlide,
}: {
  item: {
    id: number;
    content: string;
    image: string;
    image2x: string;
  };
  activeSlide: number;
  slides: {
    id: number;
    content: string;
    image: string;
    image2x: string;
  }[];
  setActiveSlide: (index: number) => void;
}) => {
  const transition = useTransition(item, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  } as React.CSSProperties);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (activeSlide === slides.length - 1) {
        setActiveSlide(0);
      } else {
        setActiveSlide(activeSlide + 1);
      }
    },
    onSwipedRight: () => {
      if (activeSlide === 0) {
        setActiveSlide(slides.length - 1);
      } else {
        setActiveSlide(activeSlide - 1);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <AnimatedContainer {...handlers}>
      {transition((style, item) => (
        <animated.div key={item.id} style={style}>
          <ProjectSlide>
            <ProjectImgContainer>
              <img src={item.image} srcSet={`${item.image2x} 2x`} alt="" />
            </ProjectImgContainer>
            <ProjectContent>
              <ProjectP
                dangerouslySetInnerHTML={{
                  __html: item.content,
                }}
              />
              {/* <ProjectBtnWrapper>
                <PaginationContainer>
                  {slides.map((_, i) => {
                    if (i === activeSlide) {
                      return <PaginationButtonActive />;
                    }
                    return (
                      <PaginationButton onClick={() => setActiveSlide(i)} />
                    );
                  })}
                </PaginationContainer>
                <ProjectButton
                  onClick={() => {
                    if (activeSlide === slides.length - 1) {
                      setActiveSlide(0);
                    } else {
                      setActiveSlide(activeSlide + 1);
                    }
                  }}
                >
                  Więcej <ArrowRight />
                </ProjectButton>
              </ProjectBtnWrapper> */}
            </ProjectContent>
          </ProjectSlide>
        </animated.div>
      ))}
    </AnimatedContainer>
  );
};

const ProjectsSection = () => {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const projectsSlides: {
    id: number;
    content: string;
    image: string;
    image2x: string;
    icon: string;
  }[] = [
    {
      id: 1,
      content:
        "🚗&nbsp;Samochód to podstawowe narzędzie pracy przedstawiciela handlowego w firmie Podravka, dla której zrealizowaliśmy już kilka edycji grywalizacji. W tej najnowszej, zdecydowaliśmy się zatem na scenariusz, w którym gracze realizując targety sprzedażowe i inne zadania, zdobywają punkty paliwa, ⛽&nbsp;a progres wykonania planu wizualizowany jest w postaci torów wyścigowych.&nbsp;🏁<br /><br />Awans na kolejne poziomy doświadczenia to dostęp do atrakcyjniejszych modeli aut.&nbsp;👍 Indywidualiści mogą także zmieniać ich kolor, a wirtualne pojazdy pojawiają się w ogólnodostępnych rankingach, dając ich właścicielom powód do dumy&nbsp;👏 i motywując innych do poprawy swoich wyników. Komu najszybciej uda się zasiąść za sterami topowego, ❤️&nbsp;czerwonego Ferrari?",
      image: ProjectsImg,
      image2x: ProjectsImg2x,
      icon: PodravkaLogo,
    },
    {
      id: 2,
      content:
        "💰&nbsp;Złoto Agentów, to gra w której blisko dwustu przedstawicieli Grupy MPT przemierza ulice wirtualnego miasta, 🔍 szukając ukrytego w jednym z biurowców skarbu. Niezbędne do poszukiwań punkty zdobywają, realizując cele sprzedażowe, 📊, biorąc udział w quizach wiedzy produktowej, ✔ a także wypełniając zadania dystrybucyjne w obsługiwanych sklepach.<br /><br />Wielkość nagrody zależy od tego, jak szybko uczestnicy dotrą do miejsca ukrycia sejfu. ⌛ Ten i kilka innych zabiegów wplecionych w fabułę gry, wpływają na wysokie zaangażowanie w realizację zadań objętych grywalizacją.&nbsp;👍",
      image: Projects2Img,
      image2x: Projects2Img2x,
      icon: MptLogo,
    },
    {
      id: 3,
      content:
        "📱&nbsp;Powrót Mistrzów to grywalizacja zrealizowana przez nas dla zespołu handlowców firmy Mastermedia – lidera dystrybucji polskiej żywności w Wielkiej Brytanii oraz krajach UE.<br /><br />Niezwykle oryginalna, momentami wręcz zawadiacka fabuła gry, 🙃 w której uczestnicy rozwijają swoją postać, przy okazji robiąc pranki swoim kolegom. Wyjątkowego charakteru zabawie dodaje fakt, że postaci w grze zostały odwzorowane na podstawie przekazanych fotografii graczy.&nbsp;👨<br /><br />Gra posiada jednocześnie bardzo rozwinięty moduł analityki sprzedaży. 📈&nbsp;Dostarcza przedstawicielom kompletny zestaw danych i wskaźników, wykorzystywanych skutecznie w pracy z klientami.&nbsp;👍",
      image: Projects3Img,
      image2x: Projects3Img2x,
      icon: MastermediaLogo,
    },
  ];

  return (
    <ProjectsContainer id="projekty">
      <SectionHeader>
        <span className="text-yellow">GRAMY</span> DLA NAJLEPSZYCH
      </SectionHeader>
      <LogoSliderNav
        activeIndex={activeSlide}
        slides={projectsSlides}
        setActiveSlide={(slide) => {
          setActiveSlide(slide);
        }}
      />
      <AnimatedDisplay
        item={projectsSlides[activeSlide]}
        activeSlide={activeSlide}
        slides={projectsSlides}
        setActiveSlide={(slide) => {
          setActiveSlide(slide);
        }}
      />
    </ProjectsContainer>
  );
};

export default ProjectsSection;
