import styled from 'styled-components'
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md'

export const HeroContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 30px;
  height: auto;
  // min-height: 550px;
  position: relative;
  z-index: 1;
  max-width: 1500px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    // min-height: 800px;
  }
`

export const HeroInnerContainer = styled.div`
  margin: 150px auto 0 auto;
  width: 100%;
  @media screen and (max-width: 768px) {
    margin-top: 100px;
  }
`

export const HeroSlide = styled.div`
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  margin-right: 24px;

  @media screen and (max-width: 480px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  // position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 768px) {
    order: 1;
    align-items: center;
  }
`

export const HeroImgContainer = styled.div`
  img {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`

export const HeroTitle = styled.h1`
  color: #fff;
  font-size: 30px;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 27px;
    order: 1;
  }

  @media screen and (max-width: 480px) {
    font-size: 21px;
    order: 1;
  }
`

export const HeroSubtitle = styled.h1`
  color: #fff;
  font-size: 26px;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 24px;
    order: 2;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
    order: 2;
  }
`

export const HeroP = styled.p`
  margin-top: 24px;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  max-width: 600px;
  min-height: 220px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    text-align: center;
    order: 3;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
    text-align: center;
    order: 3;
  }
`

export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    order: 0;
    margin-top: 0px;
    margin-bottom: 30px;
  }
`

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`

export const PaginationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-right: 20px;
  @media screen and (max-width: 768px) {
    // margin-left: 0;
  }
`

export const PaginationButton = styled.button`
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: 5px;
  background-color: white;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    height: 11px;
    width: 11px;
  }
`
export const PaginationButtonActive = styled.button`
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: 5px;
  background-color: #ffc20e;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    height: 11px;
    width: 11px;
  }
`
export const HeroButton = styled.button`
  background: #ffc20e;
  white-space: nowrap;
  padding: 12px 30px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease-in-out;

  &:hover {
    transition: all 0.25s ease-in-out;
    background: #fff;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const AnimatedContainer = styled.div`
  position: relative;
  height: 400px;
  width: 100%;

  @media screen and (max-width: 768px) {
    height: 800px;
  }

  @media screen and (max-width: 480px) {
    height: 600px;
  }
`
