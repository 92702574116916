import React from 'react';
import { TeamContainer, TeamWrapper, TeamCarouselWrapper, Item, HrLine } from './TeamElements';
import Carousel from 'react-elastic-carousel';
import BartekK from '../../assets/employees/bartekk.png';
import Justyna from '../../assets/employees/justyna.png';
import Kate from '../../assets/employees/kate.png';
import Rafal from '../../assets/employees/rafal.png';
import TomekC from '../../assets/employees/tomekc.png';
import TomekW from '../../assets/employees/tomekw.png';
import { SectionHeader } from '../SectionHeader';

const breakPoints: {
	width: number;
	itemsToShow: number;
	itemsToScroll: number;
}[] = [
	{ width: 1, itemsToShow: 1, itemsToScroll: 1 },
	{ width: 480, itemsToShow: 2, itemsToScroll: 1 },
	{ width: 768, itemsToShow: 2, itemsToScroll: 1 },
	{ width: 991, itemsToShow: 3, itemsToScroll: 1 },
];

const TeamSection = () => {
	return (
		<TeamContainer id="zespol">
			<SectionHeader>
				CZEKAMY NA <span className="text-yellow">TWOJE WYZWANIA</span>
			</SectionHeader>
			<TeamWrapper>
				👁 Poznaj ekspertów i pasjonatów, z którymi stworzysz ekscytującą 💥, niepowtarzalną 🎯 i skuteczną 📈 grywalizację dla Twojego zespołu.
				{/* <br />
        <strong>A może chcesz do nas dołączyć?</strong> Przesuń ekran w lewo aby
        zobaczyć kogo szukamy. */}
			</TeamWrapper>
			<TeamCarouselWrapper>
				<Carousel
					isRTL={false}
					focusOnSelect={true}
					// easing="cubic-bezier(1,.15,.55,1.54)"
					// tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
					transitionMs={700}
					breakPoints={breakPoints}
				>
					<Item>
						<img src={TomekC} alt="" />
						<h4>
							<span className="text-yellow">Tomek</span>
						</h4>
						<h6>Founder & CEO</h6>
						<HrLine />
						<p>
							Dawniej dyrektor zarządzający siłami sprzedaży, największego polskiego dystrybutora w branży FMCG. 💡&nbsp;
							<span className="text-yellow">Odnalazł swoje powołanie w kreowaniu narzędzi wspierających zaangażowanie i motywację poprzez mechanizmy grywalizacji</span>&nbsp;📈.
							<br />
							<br />
							Jako praktyk doskonale dogaduje się z biznesem, pozyskując dla naszych narzędzi kolejnych klientów. Prywatnie koszykarz - amator&nbsp;🏀 i (jakże by inaczej) miłośnik gier komputerowych.
						</p>
					</Item>
					<Item>
						<img src={Kate} alt="" />
						<h4>
							<span className="text-yellow">Kate</span>
						</h4>
						<h6>Lead UI Designer</h6>
						<HrLine />
						<p>
							🎨 Interface, który wspiera użytkowników w realizacji celów biznesowych ale <span className="text-yellow">wyglądem przypomina wciągającą grę a nie nudne tabelki w Excelu?</span> Trudne,
							ale nie dla Kate 🙂
							<br />
							Zapalona biegaczka 🏃‍♀️, fanka użytkowego designu, która nigdzie nie zapuszcza korzeni na dłużej. Aktualnie kreatywność czerpie z szumu czarnomorskich fal&nbsp;🌊 i blasku bułgarskiego
							słońca&nbsp;☀, ale nawet Kate nie wie gdzie będzie za rok/dwa. Ale skoro potrafimy pracować zdalnie to <span className="text-yellow">świat nie ma dla nas granic&nbsp;</span>🙂
						</p>
					</Item>
					<Item>
						<img src={BartekK} alt="" />
						<h4>
							<span className="text-yellow">Bartek</span>
						</h4>
						<h6>Project Manager</h6>
						<HrLine />
						<p>
							🏆 Ma na swoim koncie <span className="text-yellow">dziesiątki zrealizowanych z sukcesami projektów</span> wsparcia sprzedaży. Jak nikt inny potrafi{' '}
							<span className="text-yellow">łączyć kreatywność koncepcji i efektywność biznesową</span> tworzonych narzędzi.
							<br />
							<br />
							Perfekcjonista w pracy i w życiu prywatnym, fan dobrego filmu 🎬 (ponoć obejrzał ich prawie dziesięć tysięcy) i rekordzista w ilości city brake'ów w naszej firmie&nbsp;👍.
						</p>
					</Item>
					<Item>
						<img src={Justyna} alt="" />
						<h4>
							<span className="text-yellow">Justyna</span>
						</h4>
						<h6>Project Manager</h6>
						<HrLine />
						<p>
							📁 Strażniczka deadline'ów, mistrzyni Excela, <span className="text-yellow">niezrównana wszędzie tam, gdzie w grę wchodzą cyfry, procenty, wykresy i wszelkiej maści analityka</span> lub
							raporty&nbsp;🔍
							<br />
							<br />
							Prawdziwa "ogarniaczka tematów"&nbsp;🔥, której niestraszne żadne zadania. Po pokonaniu wszelkich trudności, najchętniej odpoczywa w nieznanych i nieoczywistych zakątkach naszego
							pięknego kraju.
						</p>
					</Item>
					<Item>
						<img src={TomekW} alt="" />
						<h4>
							<span className="text-yellow">Tomek</span>
						</h4>
						<h6>Full Stack Developer</h6>
						<HrLine />
						<p>
							<span className="text-yellow">Solidny, poukładany, zawsze spokojny i koleżeński</span>. Możesz liczyć na pomoc nawet gdy przychodzisz z duuużym problemem&nbsp;👍. Jak przystało na
							prawdziwego full stack'a <span className="text-yellow">podchodzi do projektów kompleksowo i naprawdę trudno go zaskoczyć</span>.<br />
							<br />
							Stoicką cierpliwość czerpie z długich spacerów na łonie natury&nbsp;🌳🌤🌳. W czasie jednego z nich uratował czwórkę małych kociąt i podarował im nowe życie. Brawo Tomek&nbsp;👏
						</p>
					</Item>
					<Item>
						<img src={Rafal} alt="" />
						<h4>
							<span className="text-yellow">Rafał</span>
						</h4>
						<h6>Support Manager</h6>
						<HrLine />
						<p>
							Mistrz dobrego pióra&nbsp;🖋. Zawsze znajdzie pomysł, jak spuentować każdą sytuację dobrym żartem lub memem.{' '}
							<span className="text-yellow">Odpowiada za wsparcie użytkowników naszych aplikacji</span>.&nbsp;💬
							<br />
							<br />
							Sprawnie rozwiązuje wszystkie zgłoszenia, pełniąc jednocześnie rolę adwokata usera na spotkaniach projektowych.{' '}
							<span className="text-yellow">
								Wiele <span style={{ whiteSpace: 'nowrap' }}>UX-owych</span> zmian w aplikacjach ma właśnie taką genezę
							</span>
							. Poza tym zajmuje się szeroko rozumianym rozwojem osobistym: liczy kalorie, pokonane piętra i oczywiście wyjaśnione sprawy&nbsp;✅
						</p>
					</Item>
				</Carousel>
			</TeamCarouselWrapper>
		</TeamContainer>
	);
};

export default TeamSection;
