import styled from 'styled-components'

export const ArrowButton = styled.button`
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 1.5em;
  background-color: #ffc20e;
  color: #333;
  box-shadow: 0 0 2px 0px #333;
  border-radius: 50%;
  border: none;
  padding: 0;
  width: 40px;
  height: 40px;
  min-width: 40px;
  line-height: 40px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  cursor: pointer;
  outline: none;
  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
    min-width: 40px;
    line-height: 40px;
    font-size: 1.4em;
  }
  @media screen and (max-width: 480px) {
    width: 30px;
    height: 30px;
    min-width: 30px;
    line-height: 30px;
    font-size: 1.2em;
  }
`