import React from 'react'
import ScrollToTop from '../components/ScrollToTop'

const TestPage = () => {
  return (
    <>
      <ScrollToTop />
      <h1>Podstrona testowa</h1>
    </>
  )
}

export default TestPage
