import React, { useState } from "react";
import {
  HeroSlide,
  HeroBtnWrapper,
  HeroContent,
  HeroP,
  HeroImgContainer,
  HeroContainer,
  AnimatedContainer,
  HeroInnerContainer,
} from "./HeroElements";
import AboutGrywalizacjaImg from "../../assets/sections-images/about-grywalizacja-img.png";
import AboutGrywalizacjaImg2x from "../../assets/sections-images/about-grywalizacja-img@2x.png";
import AboutImg from "../../assets/sections-images/about-img.png";
import AboutImg2x from "../../assets/sections-images/about-img@2x.png";
import About2Img from "../../assets/sections-images/about2-img.png";
import About2Img2x from "../../assets/sections-images/about2-img@2x.png";
import AboutFunkcjonalnoscImg from "../../assets/sections-images/about-funkcjonalnosc-img.png";
import AboutFunkcjonalnoscImg2x from "../../assets/sections-images/about-funkcjonalnosc-img@2x.png";
import { animated, useTransition } from "react-spring";
import { useSwipeable } from "react-swipeable";
import { SectionHeader } from "../SectionHeader";
import LogoSliderNav from "../LogoSliderNav";

import GrywalizacjaIcon from '../../assets/icons/grywalizacja.png'
import MotywacjaIcon from '../../assets/icons/motywacja.png'
import InformacjaIcon from '../../assets/icons/informacja.png'
import FunkcjonalnoscIcon from '../../assets/icons/funkcjonalnosc.png'

const AnimatedDisplay = ({
  item,
  activeSlide,
  slides,
  setActiveSlide,
}: {
  item: {
    id: number;
    title: string;
    subtitle: string;
    content: string;
    image: string;
    image2x: string;
  };
  activeSlide: number;
  slides: {
    id: number;
    title: string;
    subtitle: string;
    content: string;
    image: string;
    image2x: string;
  }[];
  setActiveSlide: (index: number) => void;
}) => {
  const transition = useTransition(item, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  } as React.CSSProperties);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (activeSlide === slides.length - 1) {
        setActiveSlide(0);
      } else {
        setActiveSlide(activeSlide + 1);
      }
    },
    onSwipedRight: () => {
      if (activeSlide === 0) {
        setActiveSlide(slides.length - 1);
      } else {
        setActiveSlide(activeSlide - 1);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <AnimatedContainer {...handlers}>
      {transition((style, item) => (
        <animated.div key={item.id} style={style}>
          <HeroSlide>
            <HeroContent>
              <HeroP
                dangerouslySetInnerHTML={{
                  __html: item.content,
                }}
              />
              <HeroBtnWrapper>
                {/* <ArrowButton
                  type="button"
                  onClick={() => {
                    if (activeSlide === 0) {
                      setActiveSlide(slides.length - 1);
                    } else {
                      setActiveSlide(activeSlide - 1);
                    }
                  }}
                >
                  ❮
                </ArrowButton> */}
                {/* <HeroButton
                  onClick={() => {
                    if (activeSlide === slides.length - 1) {
                      setActiveSlide(0)
                    } else {
                      setActiveSlide(activeSlide + 1)
                    }
                  }}
                >
                  Więcej <ArrowRight />
                </HeroButton> */}
                {/* <PaginationContainer>
                  {slides.map((_, i) => {
                    if (i === activeSlide) {
                      return <PaginationButtonActive />;
                    }
                    return (
                      <PaginationButton onClick={() => setActiveSlide(i)} />
                    );
                  })}
                </PaginationContainer>
                <ArrowButton
                  type="button"
                  onClick={() => {
                    if (activeSlide === slides.length - 1) {
                      setActiveSlide(0);
                    } else {
                      setActiveSlide(activeSlide + 1);
                    }
                  }}
                >
                  ❯
                </ArrowButton> */}
              </HeroBtnWrapper>
            </HeroContent>
            <HeroImgContainer>
              <img src={item.image} srcSet={`${item.image2x} 2x`} alt="" />
            </HeroImgContainer>
          </HeroSlide>
        </animated.div>
      ))}
    </AnimatedContainer>
  );
};

const HeroSection = () => {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const aboutUsSlides: {
    id: number;
    title: string;
    subtitle: string;
    content: string;
    image: string;
    image2x: string;
    icon: string;
  }[] = [
    {
      id: 1,
      title: "TWORZYMY",
      subtitle: "GRY IDEALNE",
      content:
        "To co wyróżnia nasze grywalizacje 🏆 to optymalne połączenie trzech elementów składowych:<p style='margin-bottom: 8px; margin-top: 8px;'>✅ ciekawego motywującego scenariusza gry, 🎮 dopasowanego do specyfiki Twojej firmy</p><p style='margin-bottom: 8px;'>✅ aplikacji mobilnej 📱 gwarantującej łatwość i ergonomię dostępu użytkowników do danych biznesowych oraz innych informacji&nbsp;👍</p><p>✅ zestawu narzędzi i rozwiązań, które usprawniają komunikację, wskazują potencjały wzrostu oraz uławiają analizowanie wyników&nbsp;📊</p>",
      image: AboutGrywalizacjaImg,
      image2x: AboutGrywalizacjaImg2x,
      icon: GrywalizacjaIcon,
    },
    {
      id: 2,
      title: "TWORZYMY",
      subtitle: "GRY IDEALNE",
      content:
        "Nie ma dobrej grywalizacji bez zaangażowania. Przykładamy szczególną wagę do fabuły gry oraz motywujących zasad, które inspirują do działania. Dlatego:<p style='margin-bottom: 8px; margin-top: 8px;'>✅ przygotujemy dla Ciebie indywidualny scenariusz&nbsp;🖋 i unikalną kreację&nbsp;🎨</p><p style='margin-bottom: 8px;'>✅ zbudujemy zasady grywalizacji w taki sposób, aby zapewnić motywację całego zespołu jak i każdego uczestnika z osobna, a jednocześnie wspierać realizację Twoich celów biznesowych&nbsp;👍</p><p>✅ nie oferujemy rozwiązań „pudełkowych”, tworzymy wyłącznie oprogramowanie „szyte na miarę”, uwzględniające specyfikę firmy i jej potrzeby biznesowe&nbsp;🎯</p>",
      image: AboutImg,
      image2x: AboutImg2x,
      icon: MotywacjaIcon,
    },
    {
      id: 3,
      title: "TWORZYMY",
      subtitle: "GRY IDEALNE",
      content:
        "Grywalizacje to nie tylko rozrywka. By wspierać realizację celów biznesowych, wyposażyliśmy nasz system w zaawansowane rozwiązania analityczne. Dzięki nim:<p style='margin-bottom: 8px; margin-top: 8px;'>✅ Twój zespół ma w zasięgu ręki informacje, które skutecznie pomagają w realizacji zadań&nbsp;📊</p><p style='margin-bottom: 8px;'>✅ poprzez panel administracyjny zarządzasz grą oraz monitorujesz na bieżąco wyniki poszczególnych uczestników i całych zespołów&nbsp;🧐</p><p>✅ obserwując wybrane wskaźniki, możesz wykorzystać funkcjonalności platformy i aktywnie wpływać na przebieg grywalizacji, będąc jej prawdziwym reżyserem, a nie tylko biernym obserwatorem&nbsp;😎</p>",
      image: About2Img,
      image2x: About2Img2x,
      icon: InformacjaIcon,
    },
    {
      id: 4,
      title: "TWORZYMY",
      subtitle: "GRY IDEALNE",
      content:
        "Naszą misją jest dostarczanie użytkownikom narzędzi praktycznych i kompleksowych. Dlatego ekosystem grywalizacji zawiera szereg stale rozwijanych mikroserwisów:<p style='margin-bottom: 8px; margin-top: 8px;'>✅ eduBox, misje specjalne oraz aktywatory w wyzwaniach, 🔥 to tylko część rozwiązań pozwalających na szerokie dopasowanie formuły zadań do potrzeb biznesowych Twojej firmy</p><p style='margin-bottom: 8px;'>✅ karta klienta, zawierająca pakiet czytelnych wskaźników i wykresów 📊 sekcja aplikacji, podpowiadająca uczestnikom najlepszą drogę do realizacji celów</p><p>✅ zintegrowany z aplikacją i panelem moduł chatBox, 💬 pozwalający na efektywną komunikację uczestników z operatorem gry</p>",
      image: AboutFunkcjonalnoscImg,
      image2x: AboutFunkcjonalnoscImg2x,
      icon: FunkcjonalnoscIcon,
    },
  ];

  return (
    <HeroContainer id="onas">
      <HeroInnerContainer>
        <SectionHeader>
          TWORZYMY <span className="text-yellow">GRYWALIZACJE KOMPLETNE</span>
        </SectionHeader>
        <LogoSliderNav
          activeIndex={activeSlide}
          slides={aboutUsSlides}
          setActiveSlide={(slide) => {
            setActiveSlide(slide);
          }}
      />
      </HeroInnerContainer>
      <AnimatedDisplay
        item={aboutUsSlides[activeSlide]}
        activeSlide={activeSlide}
        slides={aboutUsSlides}
        setActiveSlide={(slide) => {
          setActiveSlide(slide);
        }}
      />
    </HeroContainer>
  );
};

export default HeroSection;
