import React from "react";
import {
  ContactContainer,
  ContactWrapperTop,
  ContactWrapperBottom,
  ContactCol,
  TopImgContainer,
  TopTxtContainer,
  SocialLinkContainer,
} from "./ContactElements";
import AddressIcon from "../../assets/icons/contact-address.png";
import AddressIcon2x from "../../assets/icons/contact-address@2x.png";
import MailIcon from "../../assets/icons/contact-mail.png";
import MailIcon2x from "../../assets/icons/contact-mail@2x.png";
import PhoneIcon from "../../assets/icons/contact-phone.png";
import PhoneIcon2x from "../../assets/icons/contact-phone@2x.png";
import FbIcon from "../../assets/icons/contact-fb.png";
import FbIcon2x from "../../assets/icons/contact-fb@2x.png";
import LinkedinIcon from "../../assets/icons/contact-linkedin.png";
import LinkedinIcon2x from "../../assets/icons/contact-linkedin@2x.png";
import { SectionHeader } from "../SectionHeader";

const ContactSection = () => {
  return (
    <ContactContainer id="kontakt">
      <SectionHeader>
        <span className="text-yellow">SKONTAKTUJ SIĘ</span> Z NAMI
      </SectionHeader>
      <ContactWrapperTop>
        <ContactCol>
          <TopImgContainer>
            <img src={AddressIcon} srcSet={`${AddressIcon2x} 2x`} alt="" />
          </TopImgContainer>
          <TopTxtContainer>
            ul. Bursaki 18 lokal 17
            <br /> 20-150 Lublin
          </TopTxtContainer>
        </ContactCol>
        <ContactCol>
          <TopImgContainer>
            <img src={MailIcon} srcSet={`${MailIcon2x} 2x`} alt="" />
          </TopImgContainer>
          <TopTxtContainer>
            <a href="mailto:info@tcreate.pl">info@tcreate.pl</a>
          </TopTxtContainer>
        </ContactCol>
        <ContactCol>
          <TopImgContainer>
            <img src={PhoneIcon} srcSet={`${PhoneIcon2x} 2x`} alt="" />
          </TopImgContainer>
          <TopTxtContainer>502 004 230</TopTxtContainer>
        </ContactCol>
      </ContactWrapperTop>
      <ContactWrapperBottom>
        <ContactCol>
          <TopImgContainer>
            <a
              href="https://facebook.com/myTCreate/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={FbIcon} srcSet={`${FbIcon2x} 2x`} alt="" />
            </a>
          </TopImgContainer>
          <SocialLinkContainer>
            <a
              href="https://facebook.com/myTCreate/"
              target="_blank"
              rel="noreferrer"
            >
              facebook.com/myTCreate/
            </a>
          </SocialLinkContainer>
        </ContactCol>
        <ContactCol>
          <TopImgContainer>
            <a
              href="https://linkedin.com/company/tcreate"
              target="_blank"
              rel="noreferrer"
            >
              <img src={LinkedinIcon} srcSet={`${LinkedinIcon2x} 2x`} alt="" />
            </a>
          </TopImgContainer>
          <SocialLinkContainer>
            <a
              href="https://linkedin.com/company/tcreate"
              target="_blank"
              rel="noreferrer"
            >
              linkedin.com/company/tcreate
            </a>
          </SocialLinkContainer>
        </ContactCol>
      </ContactWrapperBottom>
    </ContactContainer>
  );
};

export default ContactSection;
